<template>
  <div class="board" style="background-color:white;">
    <div class="board_column" style="background-color:white;height:100%">
      <div class="fill-row1" style="background-color:#f7f7f7;height:100%">
        <Tree :data="treedata" select-node @on-select-change="treeclick"></Tree>
      </div>
      <div class="fill-row8933 margin-left-10" style="height:100%;width:100%">
        <div class="button_box flex-row">
          <div class="fill-row5  text-left">
            <Button type="primary">保存</Button>
            <Select style="width:100px;margin-left:10px">
              <Option v-for="category in categoryOption" :value="category.value" :key="category.value">{{category.name}}</Option>
            </Select>
          </div>
          <div class="fill-row5  text-right">
            <Input placeholder="" style="width:100px" />
            <Button type="primary">查询</Button>
            <Button type="primary" class="margin-left-10">下载</Button>
          </div>
        </div>
        <div class="table_box">
          <Table row-key="id" @on-sort-change="sortchange" :height="table_height" @on-row-cilick="rowclick" @on-cell-click="cellclick" size="small" :columns="workhour_columns" :data="workhour_data" border></Table>
        </div>
        <div class="button_box margin">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/format.js";
import { } from "@/api";
import { } from "@/utils/action.js";
// import table from '@/components/web/tablebox'
export default {
  data: function () {
    return {
      treedata: [

        {
          title: '商机阶段',
          expand: true,
          children: [
            {
              title: '已关闭',
              key: "1"
            },
            {
              title: '商机跟进',
              key: "2"
            },
            {
              title: '需求确认',
              key: "3"
            },
            {
              title: '方案汇报',
              key: "4"
            },
            {
              title: '招投标',
              key: "5"
            }
          ],

        },
        {
          title: '合同阶段',
          expand: true,
          children: [
            {
              title: '转合同',
              key: "6"
            },
            {
              title: '项目实施',
              key: "7"
            },
            {
              title: '验收',
              key: "8"
            },
            {
              title: '总结',
              key: "9"
            },
            {
              title: '已冻结',
              key: "10"
            }
          ]
        }


      ],
      //单元格颜色
      workhour_columns: [
        {
          title: '姓名',
          key: 'name',
          width: 80,
          sortable: "custom",
        },
        {
          title: '1日',
          key: 'day1',
          sortable: "custom",
        },
        {
          title: '2日',
          key: 'day2'
        },
        {
          title: '3日',
          key: 'day3'
        },
        {
          title: '4日',
          key: 'day4'
        },

        {
          title: '5日',
          key: 'day5'
        },
        {
          title: '6日',
          key: 'day6'
        },
        {
          title: '7日',
          key: 'day7'
        },
        {
          title: '8日',
          key: 'day8'
        },
        {
          title: '合计',
          key: 'sum'
        },
      ],
      workhour_data: [
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },

        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },

        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '徐维鑫',
          day1: 1,
          day2: 2,
          day3: 3,
          day4: 4,
          day5: 5,
          day6: 6,
          day7: 7,
          day8: 8,
        },
        {
          name: '杨孟',
          day1: 9,
          day2: 12,
          day3: 2,
          day4: 5,
          day5: 5,
          day6: 35,
          day7: 7,
          day8: 5,
        },
        {
          name: '袁周',
          day1: 25,
          day2: 25,
          day3: 25,
          day4: 25,
          day5: 25,
          day6: 25,
          day7: 25,
          day8: 25,
        },
      ],
      table_height: window.innerHeight - 120,
      categoryOption: [
        { name: "1", value: "1" },
        { name: "2", value: "2" },
      ]
    }
  },
  methods: {
    formatDate: formatDate,
    treeclick(item, i) {
      console.log(item)
      console.log(i);
    },
    cellclick(row, column, data, event) {
      console.log(row);
      console.log(column);
      console.log(data);
      console.log(event);
      this.history = [
        {
          time: "2021-01-03 12:12:12",
          projectname: "lims环科院",
          user: "张宏凯",
          use: "6",
          content: "设计生成原始记录Excel文件整理原始记录单查询所需要的sql"
        },
        {
          time: "2021-01-03 12:12:12",
          projectname: "lims环科院",
          user: "张宏凯",
          use: "6",
          content: "设计生成原始记录Excel文件整理原始记录单查询所需要的sql"
        }];
      this.drawer_visable = true;
    },
    rowclick(row) {
      console.log(row)
    },
    sortchange(option) {
      console.log(option);
    }
  },
  mounted() {
    this.table_height = window.innerHeight - 120;
  }
}
</script>


